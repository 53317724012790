.featurebox {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.featureImagebox{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0%;
}
.contentBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    flex-wrap: wrap;
    flex-direction: column;
    display: flex;
    gap: 15px;  
}
.title{
    font-size: 21px !important;
}
.circlenumber{
    background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex; 
    justify-content: center; 
    align-items: center;
    color: #fff;
    position: absolute;
    top: -40px;
    right: 10px;
    font-weight: 600;
    font-size: 20px !important;
   
}

.pen{
    vertical-align: text-top;
    transform: translateY(-10px);
}

.featureImage{
 object-fit:contain;
 width: 100%;
 height: 320px;
 filter: drop-shadow(2px 3px 0px #135DA8);
}
.ImageBox{
     position: relative;
     padding: 1px 5px 1px 5px;
}
.tryButton{
    position: absolute;
    top: 80%;
    right: 15px;
}

@media screen and (max-width: 350px) {
    .featurebox {
        background-image: url(../../../Assets/featurebox.png);
        background-size: contain;
        background-repeat: no-repeat;
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        gap:10px;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 80px;
    }
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: -40px;
        right: 10px;
        font-weight: 600;
        font-size: 20px !important;
       
    }
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 80%;
           right: 15px;
       }
}

@media screen  and (min-width: 351px) and (max-width: 599px) {
    .featurebox {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 120px;
    }
    
    .featureImagebox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
    }
    
    .contentBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        gap: 15px;  
    }
    
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 50px;
        height:50px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: -20px;
        right: 5px;
        font-weight: 600;
        font-size: 20px !important;
       
    }
    
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 65%;
           right: 15px;
       }
    .content{
        font-size: 10px !important;
    }
    .title{
        font-size: 14px !important;
    }
}

@media screen and (width: 600px) {
    .featurebox {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 120px;
    }
    
    .featureImagebox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
    }
    
    .contentBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        gap: 15px;  
    }
    
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 50px;
        height:50px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: 0px;
        right: -10px;
        font-weight: 600;
        font-size: 20px !important;
       
    }
    
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 55%;
           right: 15px;
       }
    .content{
        font-size: 10px !important;
    }
    .title{
        font-size: 14px !important;
    }
}


@media screen  and (min-width: 601px) and (max-width: 767px) {
    .featurebox {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 120px;
    }
    
    .featureImagebox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
    }
    
    .contentBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        gap: 15px;  
    }
    
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 50px;
        height:50px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: 0px;
        right: 0px;
        font-weight: 600;
        font-size: 20px !important;
       
    }
    
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 60%;
           right: 15px;
       }
    .content{
        font-size: 10px !important;
    }
    .title{
        font-size: 14px !important;
    }
}

@media screen  and (min-width: 768px) and (max-width: 940px) {
    .featurebox {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 120px;
    }
    
    .featureImagebox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
    }
    
    .contentBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        gap: 15px;  
    }
    
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 50px;
        height:50px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: 0px;
        right: 4px;
        font-weight: 600;
        font-size: 20px !important;
       
    }
    
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 65%;
           right: 15px;
       }
    .content{
        font-size: 13px !important;
    }
    .title{
        font-size: 17px !important;
    }
}

@media screen  and (min-width: 941px) and (max-width: 1100px) {
    .featurebox {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: left;
        padding: 120px;
    }
    
    .featureImagebox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0%;
    }
    
    .contentBox{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        flex-wrap: wrap;
        flex-direction: column;
        display: flex;
        gap: 15px;  
    }
    
    .circlenumber{
        background:linear-gradient(to bottom, #135DA8 0%, #BBC8D7 100%);
        border-radius: 50%;
        width: 60px;
        height:60px;
        display: flex; 
        justify-content: center; 
        align-items: center;
        color: #fff;
        position: absolute;
        top: -10px;
        right: 10px;
        font-weight: 600;
        font-size: 20px !important;
    }
    
    .featureImage{
        object-fit:contain;
        width: 100%;
        height: 320px;
        filter: drop-shadow(2px 3px 0px #135DA8);
       }
       .ImageBox{
            position: relative;
            padding: 1px 5px 1px 5px;
       }
       .tryButton{
           position: absolute;
           top: 70%;
           right: 15px;
       }
    .content{
        font-size: 15px !important;
    }
    .title{
        font-size: 18px !important;
    }
}    



/* slide right */

.animate-slide-right {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInLeft {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInLeft {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 

    .animate-slide-left {
        -webkit-animation-name: fadeInRight;
        animation-name: fadeInRight;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        }
        @-webkit-keyframes fadeInRight {
        0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        }
        100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        }
        }
        @keyframes fadeInRight {
        0% {
        opacity: 0;
        -webkit-transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
        }
        100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
        }
        } 