/* .NavBar{
  position: sticky;
  top: 0;
  z-index: 10;
}
.NavBar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; 
  z-index: 1000; 
  transition: background-color 0.3s ease; 
} */



.header {
    width: 100%;
    transition: 0.3s ease all;
    color: #000000;
  }

  .header__content {
    color: #000000;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 100;
  }
  .header__content__logo {
    font-weight: bold;
    text-decoration: none;
    color: #000000; 

  }

  .header__content__nav {
    top: 0;
    right: 100%;
    bottom: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    backdrop-filter: blur(15px);
    transform: translate(0);
    transition: 0.3s ease transform;
  }
  @media (min-width: 970px) {
    .header__content__nav {
      transform: none;
      flex-direction: row;
      background: transparent;
      width: auto;
      height: 100%;
      position: static;
    }
  }
  .header__content__nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }
  @media (min-width: 970px) {
    .header__content__nav ul {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      margin-right: calc(0.5rem + 16px);
    }
  }
  .header__content__nav ul li:not(:last-child) {
    margin-bottom: 32px;
  }
  @media (min-width: 970px) {
    .header__content__nav ul li:not(:last-child) {
      margin-bottom: 0;
      margin-right: 16px;
    }
  }
  .header__content__nav ul li a {
    text-decoration: none;
    color: #000000;
    font-weight: 550;
    font-size: 15px;
    text-transform: capitalize;
    transition: 0.3s ease all;
    padding: 20px 0;
    margin-left: 40px;
    cursor: pointer;
  }
  .header__content__nav ul li a:hover {
    color:  #135DA8;
  }
  .header__content__nav ul li a:active {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.2));
  }
  .header__content__nav .btn {
    cursor: pointer;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 700;
	  font-size: 14px;
    font-family: inherit;
    background: #a2a2f6;
    color: #000000;
    border: 1px solid transparent;
    transition: 0.3s ease all;
    margin: 0.5em 3em 0.5em;
  }

  .header__content__nav .btn__login {
    background-color: #135DA8;
    color: #fff;
  }
  .header__content__nav.isMenu {
    transform: translate(100%);
  }
  .header__content__toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 2rem;
    transition: 0.3s ease all;
    position: relative;
  }
  .header__content__toggle:hover {
    color: #a2a2f6;
  }
  @media (min-width: 970px) {
    .header__content__toggle {
      display: none;
    }
  }
  


  @media screen and (min-width: 1070px) and (max-width: 1250px) {
    .header__content__nav ul li a {
      text-decoration: none;
      color: #000000;
      font-weight: 550;
      font-size: 14px;
      text-transform: capitalize;
      transition: 0.3s ease all;
      padding: 10px 0;
      margin-left: 16px;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1070px) {
    .header__content__nav ul li a {
      text-decoration: none;
      color: #000000;
      font-weight: 550;
      font-size: 13px;
      text-transform: capitalize;
      transition: 0.3s ease all;
      padding: 10px 0;
      margin-left: 12px;
    }
  }

  @media screen and (min-width: 970px) and (max-width: 1024px) {
    .header__content__nav ul li a {
      text-decoration: none;
      color: #000000;
      font-weight: 550;
      font-size: 12px;
      text-transform: capitalize;
      transition: 0.3s ease all;
      padding: 10px 0;
      margin-left: 9px;
    }
  }
  