body {
  font-family: 'Gilroy-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;

}

@font-face {
  font-family: Gilroy-Medium;
  src: url(./Components/Fonts/Gilroy-Medium.ttf);
}