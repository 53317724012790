.AboutdemoImage{
    height: auto;
    width: 50%;
}
.AboutdemoImage1{
    height: auto;
    width: 50%;
}

@media screen and (min-width: 899px) {
    .AboutdemoImage1{
        height: auto;
        width: 50%;
        transform: translateY(50%);
    }
}

@media screen and (min-width: 1300px) {
    .AboutdemoImage1{
        height: auto;
        width: 50%;
        transform: translateY(30%);
    }
}