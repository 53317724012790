.xs-icon{
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 11px;
    margin-right: 10px;
    font-weight: 400;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    background-color: #135DA8;
    color:#135DA8;
  }

.demoImageContainer{
  background: linear-gradient(135deg, #FBB8B6 0%, #A7A5F3 99.48%);
  padding: 10px;
  border-radius: 15px;
}
.demoImage{
    width: 100%;
}
.Banner{
    background:  linear-gradient(180deg, #F1F4FB 0%, rgba(255, 255, 255, 0) 100%);
}
.floatingImage1{
    position: absolute;
    top:-50px;
    left: 168px;
    animation: xc-moveY 10s infinite;
    z-index: -1;
}
.floatingImage2{
    position: absolute;
    bottom: -50px;
    left: -90px;
    animation: xc-moveY 10s infinite;
    z-index: -1;
}
.floatingImage3{
    position: absolute;
     top: -20px;
    left: 0px;
    animation: xc-moveX 8s infinite;
    z-index: 1;
}
.floatingImage4{
    position: absolute;
    bottom: -120px;
    right: 10px;
    animation: xc-moveX 7s infinite;
    z-index: -1;
    height: 130px;
}


@keyframes xc-moveY {
    0% {
        transform: translatey(0px);
    }
    50% {
        transform: translatey(-20px);
    }
    
    100% {
        transform: translatey(0px);
    }
}

@keyframes xc-moveX {
0% {
    transform: translatex(0px);
}
50% {
    transform: translatex(-20px);
}
100% {
    transform: translatex(0px);
}
}
.firstLayoutTitle{
    font-size: 66px;
    letter-spacing: -0.01em;
    width: 90%;
    margin: 0 auto 10px;
}

.thirdLayout{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 20px !important;
}

.thridLayoutTitle1{
    font-weight: 800 !important;
    font-size: 54px !important;
    line-height: 1.2 !important;
}

.thridLayoutTitle2{
    font-weight: 800 !important;
    font-size: 54px !important;
    line-height: 1.2 !important;
    margin-bottom: 40px !important;
}

.floatingMic{
    position: absolute;
    top:-50px;
    left: 200px;
    animation: xc-moveX 10s infinite;
    z-index: 1;
}
.floatingVideo{
    position: absolute;
    top: 100px;
    left: 50px;
    animation: xc-moveY 10s infinite;
    z-index: 1;
}
.xc-faq-three__img {
    position: relative;
  }
  
  .xc-faq-three__img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: center;
    transform-style: preserve-3d;
    transition: transform 0.3s ease-in-out;
  }
  
  .xc-faq-three__img[data-tilt] {
    will-change: transform;
  }
  
  .xc-faq-three__img[data-tilt]:hover img {
    transform:  perspective(300px) rotateX(-5.9deg) rotateY(3.04deg) scale3d(1, 1, 1); 
  }
  

  .GlassCards{
    border: 1px solid #135DA8 !important;
    padding: 20px 25px 20px 25px;
    border-radius: 30px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content:start;
    align-content: center;
    gap:10px;
    align-items: center;
    text-align: center;
    height: 100%;
    position: relative;
  }

  /* .GlassCards:hover{
    background-image: linear-gradient(#B4C4D3, #D6DEE7);
    border: 1px solid #135DA8 !important;
  } */

.glassImage{
    margin: 0 auto;
}

.glassTitle{
    font-family: "Gilroy-Medium", sora !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 34px !important;
}
.glassContent{
  color: #747681;
}

.Banner2{
    margin-top: 70px;
}
.Banner2Title{
    color: #000000;
    font-family: "Gilroy-Medium", sora;
    font-weight: 600 !important;
    font-size: 54px !important;
    line-height: 1.2 !important;
    text-transform: capitalize;
}

.acccordTitle{
    position: relative;
    font-size: 20px !important;
    margin-bottom: 0px;
    line-height: 1.3;
}
.FAQText1{
        background:linear-gradient(to right, #2c3e50, #3498db) !important;
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: transparent !important;
}

.servicelistBox{
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.serviceList{
  font-size: 14px !important;
}

.pricingGrid .pricingCard:nth-child(1) {
    transform: scale(1);
}

.pricingGrid .pricingCard:nth-child(2) {
    transform: scale(1.09);
}

.pricingGrid .pricingCard:nth-child(3) {
    transform: scale(1);
}

.svgPricing{
  width: auto;
  height: auto;
}
.pricingCard{
  width: auto;
  height: auto;
}
.pricingBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  display: flex;
  gap:10px;
  flex-direction: column;
}

.cost{
  margin-top: 5px;
  margin-bottom: 5px;
}

.slide {
  background-image: linear-gradient(#B0C0D1, #DBE2EA);
  border-radius: 15px;
  cursor:pointer;
}

.slick-slide {
  margin-right: 15px;
}

@keyframes scroll {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-150px * 10));
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(calc(-150px * 10));
  }
  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(150px * 1);
  }

  .slide-track2 {
    width: calc(150px * 1);
  }

  .slide {
    width:500px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-100px * 10));
    }
  }
  
  @keyframes scroll2 {
    0% {
      transform: translateX(calc(-100px * 10));
    }
    100% {
      transform: translateX(0px);
    }
  }
}



/* Responsive For Homepage */

@media screen and (max-width: 899px) {
  .pricingGrid .pricingCard:nth-child(2) {
    transform: scale(1);
  }
}

@media screen and (min-width: 1094px) and (max-width: 1180px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .cost{
    margin-top:2px;
    margin-bottom:3px;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:5px;
    flex-direction: column;
  }
  .serviceList{
    font-size: 13px !important;
    }
    .serviceName{
    font-size: 17px !important;
    }
}

@media screen and (min-width: 1046px) and (max-width: 1193px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .cost{
    margin-top:2px;
    margin-bottom:3px;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:7px;
    flex-direction: column;
  }
  .serviceList{
    font-size: 13px !important;
    }
    .serviceName{
    font-size: 17px !important;
    }
}

@media screen and (min-width: 996px) and (max-width: 1045px) {
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:4px;
    flex-direction: column;
  }
  .pricingGrid .pricingCard:nth-child(2) {
    transform: scale(1);
  }
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .cost{
    margin-top:2px;
    margin-bottom:3px;
  }
  .serviceList{
    font-size: 12px !important;
    }
    .serviceName{
    font-size: 16px !important;
    }
}

@media screen and (min-width: 928px) and (max-width: 995px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .serviceList{
    font-size: 12px !important;
    }
    .serviceName{
    font-size: 14px !important;
    }
    .cost{
      margin-top:2px;
      margin-bottom:2px;
    }
    .pricingBox{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -48%);
      display: flex;
      gap:2px;
      flex-direction: column;
    }
} 

@media screen and (min-width: 900px) and (max-width: 928px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .serviceList{
    font-size: 11px !important;
    }
    .serviceName{
    font-size: 13px !important;
    }
    .cost{
      margin-top:2px;
      margin-bottom:2px;
    }
    .pricingBox{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -48%);
      display: flex;
      gap:2px;
      flex-direction: column;
    }
}




@media screen and (min-width: 800px) and (max-width: 899px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .serviceList{
    font-size: 23px !important;
    }
    .serviceName{
    font-size: 23px !important;
    }

  .svgPricing{
    width: auto;
    height: auto;
  }
  .pricingCard{
    width:auto;
    height: auto;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap:16px;
    flex-direction: column;
  }
  
  .cost{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
}  

@media screen and (min-width: 690px) and (max-width: 799px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
  .serviceList{
    font-size: 19px !important;
    }
    .serviceName{
    font-size: 19px !important;
    }

  .svgPricing{
    width: auto;
    height: auto;
  }
  .pricingCard{
    width:auto;
    height: auto;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:14px;
    flex-direction: column;
  }
  .cost{
    margin-top: 3px;
    margin-bottom: 3px;
  }
  
}

@media screen and (min-width: 601px) and (max-width: 689px) {
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap:15px;
  }
  .serviceList{
    font-size: 16px !important;
    }
    .serviceName{
    font-size: 18px !important;
    }

  .svgPricing{
    width: auto;
    height: auto;
  }
  .pricingCard{
    width:auto;
    height: auto;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:12px;
    flex-direction: column;
  }
  .cost{
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media screen and (width: 600px) {
  .pricingGrid .pricingCard:nth-child(2) {
    transform: scale(1);
  }
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap:15px;
  }
  .serviceList{
    font-size: 16px !important;
    }
    .serviceName{
    font-size: 18px !important;
    }

  .svgPricing{
    width: auto;
    height: auto;
  }
  .pricingCard{
    width:auto;
    height: auto;
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -48%);
    display: flex;
    gap:12px;
    flex-direction: column;
  }
  .cost{
    margin-top: 4px;
    margin-bottom: 4px;
  }
}

@media screen  and (min-width: 351px) and (max-width: 599px) {
.xc-faq-three__img {
  font-size: 30px !important;
  letter-spacing: -0.01em;
  width: 90%;
  margin: 0 auto 10px;
}
.floatingImage2{
  display: none;
}
.floatingImage1{
  display: none;
}
.thridLayoutTitle1{
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 1.2 !important;
}

.thridLayoutTitle2{
  font-weight: 800 !important;
  font-size: 30px !important;
  line-height: 1.2 !important;
  margin-bottom: 40px !important;
}
.Banner2Title{
  color: #000000;
  font-family: "Gilroy-Medium", sora;
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 1.2 !important;
  text-transform: capitalize;
}
.pricingGrid .pricingCard:nth-child(2) {
  transform: scale(1);
}
.pricingBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  justify-self: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.servicelistBox{
  display: flex;
  flex-direction: column;
  gap:25px;
}
.serviceList{
  font-size: 16px !important;
  }
  .serviceName{
  font-size: 23px !important;
  }
.svgPricing{
  max-width: 500px;
  height: 500px;
}
.pricingCard{
   max-width: 500px;
   height: 550px;
}
}

@media screen and (max-width: 350px) {
  .slick-slide {
    width: 300px;
  }
  .pricingGrid .pricingCard:nth-child(2) {
    transform: scale(1);
  }
  .pricingBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -48%);
    justify-self: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
  }
  .svgPricing{
    width: auto;
    height: 500px;
  }
  .pricingCard{
     width: auto;
     height: 550px;
  }
  .servicelistBox{
    display: flex;
    flex-direction: column;
    gap:25px;
  }
  .serviceList{
    font-size: 16px !important;
    }
    .serviceName{
    font-size: 23px !important;
    }
}







/* slide up */

.animate-section {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  }
  @keyframes slideInUp {
  0% {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  visibility: visible;
  }
  100% {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  }
  } 


  .animate-section-zoom {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 


    /* slide right */

.animate-group-1 {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeInLeft {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  }
  @keyframes fadeInLeft {
  0% {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  }
  100% {
  opacity: 1;
  -webkit-transform: none;
  transform: none;
  }
  } 

  .animate-group-2 {
      -webkit-animation-name: fadeInRight;
      animation-name: fadeInRight;
      -webkit-animation-duration: 1s;
      animation-duration: 1s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      }
      @-webkit-keyframes fadeInRight {
      0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      }
      100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
      }
      }
      @keyframes fadeInRight {
      0% {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      }
      100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
      }
      } 